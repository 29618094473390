import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBBySLrSBeWzEMMuTSB9gY-jC1opQWJi9A",
  authDomain: "petals-8722e.firebaseapp.com",
  projectId: "petals-8722e",
  storageBucket: "petals-8722e.appspot.com",
  messagingSenderId: "111415359735",
  appId: "1:111415359735:web:8d499a8efbccbf02623bf2",
  measurementId: "G-Z19NZHHT9E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;