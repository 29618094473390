
import React from 'react';
import Main from './TextEditable';
// import TextOpacity from './TextOpacity';

function App() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
